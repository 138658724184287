import React from "react"
import Layout from "../components/layout";
import {Col, Row} from "reactstrap";

const About = () => {

    return <Layout>
        <Row className="A__row">
            <Col className="A__col">
                <Row>
                    <Col sm="6" className="A__text">
                        <p>
                            Osobiście przeszedłem drogę od Pracownika poprzez Samozatrudnionego do Właściciela Biznesu. Odkryłem
                            też zalety bycia Inwestorem i jestem dziś wdzięczny osobom, które uświadomiły mi konieczność
                            planowania i rozwoju zarówno swojej osobowości, jak i biznesu. Wiem, co można dzięki temu osiągnąć.
                        </p>
                        <p>
                            Pierwsze kroki jako szkoleniowiec poczyniłem kilkanaście lat temu, tuż po założeniu działalności
                            gospodarczej. Spory wpływ na moje życie miały zarówno szkolenia, jak i książki takich sław jak Brian
                            Tracy, Anthony Robbins, Zig Ziglar, T.Harv Eker. Konsultingiem, coachingiem i prowadzeniem szkoleń
                            zająłem się na poważnie od 2006 roku. Dziś mogę powiedzieć, że dzięki ukierunkowaniu swojej
                            działalności na szkolenia i treningi rozwoju osobistego, jak i konsulting w biznesie, mogę o sobie
                            powiedzieć, że mam szczęście łączyć na co dzień pasję z działalnością zawodową.
                        </p>
                        <p>
                            Coach, trener biznesu
                        </p>
                        Konsultant biznesowy
                        <p>
                            ​
                        </p>
                        <p> Dynamika Talentów: Creator, Star, Mechanic
                        </p>

                        <p> Właściciel i założyciel firmy WBcom
                        </p>
                        <p> Od 2000 roku przedsiębiorca
                        </p>

                        <p> Absolwent GWSH, mgr Zarządzania Firmą
                        </p>

                        <p> Prywatnie szczęśliwy mąż
                            i ojciec dwójki radosnych dzieci.
                        </p>

                        <p> Pasja: taniec i rozwój osobisty
                        </p>

                        <p> Walt Disney kierował się świetną maksymą,
                            pod którą i ja się podpisuję:

                        </p>
                        <p> "Rób to, co robisz tak dobrze,
                            by ludzie chcieli zobaczyć to ponownie
                            i przyprowadzić swoich przyjaciół."
                        </p>
                    </Col>
                </Row>
            </Col>
        </Row>
    </Layout>
}

export default About


